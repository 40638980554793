<template>
  <div>
    <vs-card class="mx-10 p-5 w-auto">
      <div>
        <h2 class="mb-5">Page Details</h2>
        <vs-form class="mb-6">
          <vs-label class="label mt-2">Title</vs-label>
          <vs-input
            class="col-sm-3"
            v-model="pages.title"
            name="title"
            v-validate="'required|alpha_spaces|max:150'"
            data-vv-as="Title"
          />
          <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </vs-form>
        <br />
        <vs-label class="label">Content</vs-label>
        <quillEditor :pages="pages"></quillEditor><br>
        <div slot="footer" class="mb-12 mt-12">
          <vs-row>
            <vs-button @click="savePageDetails" align="left">Save Details</vs-button>
          </vs-row>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import quillEditor from "../../../Editor";

  export default {
    name: 'pageEditDetail',
    components: {
      quillEditor
    },
    data:()=>({
      pages:{
        content:'',
        title:''
      },
    }),
    methods: {
      ...mapActions("pages", [
        "getPageDetails",
        "editPageDetails"
      ]),
      getPageDetail(id) {
        this.getPageDetails(id).then(res => {
          this.pages.content = res.data.data.content
          this.pages.title = res.data.data.title
        })
      },
      async savePageDetails() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let id = this.$route.params.id
            let info = this.pages
            this.editPageDetails({id, info}).then(res => {
              this.$vs.notify({
                title: 'Update Page Content',
                text: res.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
              this.$router.push({name: 'page-list'});
            })
          }
        })
      },
    },
      created() {
        let pageId = this.$route.params.id;
        this.getPageDetail(pageId);
      }
    }
</script>
<style>
  .label {
    font-size: small;
    color: black;
  }
</style>
